body {
    background-color: #F4F4F4;
}

.box-shadow {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
}

.scrollbar-container {
    overflow: auto;
}
